<template>
  <section class="signIn">
    <div class="container-fluid">
      <div class="row">

        <div class="bg-image d-none col-12 col-lg-6 pt-5 align-content-center d-lg-flex flex-wrap">
          <router-link class="d-block" to="/">
            <img class="img-fluid" style="position: absolute; top: 30px; left: 20px" :src="require(`@/assets/back.svg`)" width="30px">
          </router-link>
          <Slider :items="items"></Slider>
        </div>
        <div class="bg-white col-12 col-lg-6 align-content-center d-flex flex-wrap">
          <div class="row mx-auto">

            <div class="col-lg-8 col-12 mx-auto text-center">
              <figure class="mb-4 text-center">
                  <img class="img-fluid px-4" :src="require(`@/assets/logo-blue.svg`)" alt="Logo" width="250">
              </figure>

              <form id="credentials">

                <p v-if="message" class="alert alert-success text_m">{{ message }}</p>
                <div v-if="!isCodeRequested">
                  <h4 class="mb-3">Dobrodošli</h4>
                  <p v-if="error" class="alert alert-danger text_m">{{ errorText }}</p>
                  <p v-else>Prijavite se jednokratnim kôdom koji ćemo Vam poslati na Vašu email adresu.</p>
                  <input class="width-input mb-3" type="text"
                         placeholder="Email adresa"
                         v-model="email"
                  >
                  <input type="button" class="d-block mx-auto btn width-input"
                         @click="requestCode()"
                         :disabled="!isEmailValid"
                         value="Zatraži kôd"
                  />
                  <p class="d-block text-left text-lg-center pt-3 text_xs"> Nemate nalog? <br class="d-block d-lg-none">Saznajte <a href="/faq/#kako-se-postaje-clan" class=""><u>kako da postanete član</u></a>. </p>
                </div>
                <div v-else>
                  <input class="width-input mb-3" type="text"
                         placeholder="Jednokratni kôd"
                         v-model="otp"
                         v-if="isCodeRequested"
                          maxlength="6"/>
                  <input type="button" class="mb-3 d-block mx-auto btn width-input"
                         @click="login()"
                         :disabled="!isOTPValid"
                         value="Uloguj se"
                  />
                  <div>
                    <p v-if="!isExpired">
                      {{countdownTimer}}
                    </p>
                    <p v-else class="text-decoration-underline" style="cursor: pointer"
                       @click="requestCode()">
                      Zatraži kôd ponovo
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Slider from '@/components/shared/Slider';
import axios from "axios";
import {checkAuth} from "../../utils/jwtUtils";

export default {
  name: "Hero",
  components: { Slider },
  data() {
    return {
      items: [
        {
          title: 'Vaš nalog',
          img: "check-icon.svg",
          content: 'Ažurirajte svoje podatke, podesite vidljivost profila u pretrazi ili personalizujte adresu svog profila (URL).'
        }, {
          title: 'Pozivajte članove',
          img: "chat-icon.svg",
          content: 'Pošaljite pozivnicu za pristup novim članovima i pratite statuse i statistiku odgovora'
        }],
      email: '',
      otp: '',
      isCodeRequested: false,
      countdownTimer: '',
      timeUntilExpiration: 0,
      isExpired: false,
      error: false,
      errorText: '',
      message: ''
    };
  },
  computed: {
    isEmailValid() {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return emailRegex.test(this.email);
    },
    isOTPValid(){
      return this.otp.length === 6;
    }
  },
  methods: {
    requestCode() {
      if (this.isEmailValid) {
        const requestData = {
          email: this.email
        };

        axios.post(`${process.env.VUE_APP_API_URL}/auth/request-code`, requestData)
            .then(response => {
              this.message = response.data.description;
              this.timeUntilExpiration = response.data.timeUntilExpiration;
              this.error = false;
              this.isCodeRequested = true;
              this.startCountdown();
            })
            .catch(error => {
              this.error = true;
              this.errorText = error.response.data.description;
            });
      } else {
        this.error = true;
        this.errorText = 'Unesite ispravnu email adresu.';
      }
    },
    login() {
      if (this.isOTPValid && this.isEmailValid) {
        const requestData = {
          email: this.email,
          otp: this.otp
        };

        axios.post(`${process.env.VUE_APP_API_URL}/auth/login`, requestData)
            .then(response => {
              this.message = response.data.description;
              this.error = false;
              if (response.data.code === 200) {
                const jwtToken = response.data.token;
                localStorage.setItem("SRPlatformJwtToken", jwtToken);
                this.$router.push('/feed');
              }
            })
            .catch(error => {
              this.message = false;
              this.error = true;
              this.errorText = error.response.data.description;
            });
      } else {
        this.error = true;
        this.errorText = 'Unesite ispravnu email adresu.';
      }
    },

    formatTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    },
    startCountdown() {
      let text = "Zatraži kôd ponovo za ";
      const countdownInterval = setInterval(() => {
        if (this.timeUntilExpiration > 0) {
          this.timeUntilExpiration--;
          this.countdownTimer = text + this.formatTime(this.timeUntilExpiration);
        } else {
          clearInterval(countdownInterval);
          this.countdownTimer = 0;
          this.isExpired = true;
        }
      }, 1000);
    },
  },
  created() {
    const isValidToken = checkAuth();
    if(isValidToken) {
      this.$router.push('/feed');
    }
  },
};
</script>

