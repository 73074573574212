<template>
	<div id="slider" class="row mx-auto">
		<div class="col-10 mx-auto text-center px-4 py-5">

			<input class="d-none" type="radio" name="slider" id="slide1" checked="">
			<input class="d-none" type="radio" name="slider" id="slide2">

			<div id="slides" >
				<div id="overflow">
					<div class="inner">
						<article v-for="(item, index) in items" :key="index" class="slide w-25 float-left">
							<figure class="pt-5 pb-4 ">
								<img class="img-fluid" :src="require(`@/assets/icons/`+ item.img)" alt="" width="" height="" >
							</figure>
							<h4 class="mb-3">
								{{ item.title }}
							</h4>
							<p class="px-3 mb-5">
								{{ item.content }}
							</p>
						</article>
					</div>
				</div>
			</div>
			<label for="slide1"></label>
			<label for="slide2" class="mx-2"></label>

		</div>

	</div>
</template>


<script>

export default {
	name: "Hero",
	props: ['items'],
	data(){
		return {
			letsSlide: null
		}
	},
	mounted(){
		setTimeout(function(){
			const slideOne = document.getElementById('slide1');
			const slideTwo = document.getElementById('slide2');

			slideOne.removeAttribute('checked')
			slideTwo.setAttribute('checked', 'checked')

		}, 2500);
	}
}
</script>